import * as React from "react"

import Seo from "../components/common/seo"
import { Container } from "../components/layout/Container"
const NotFoundPage = () => (
  <>
    <Seo title="404: Not found" />
    <Container padded>
      <h1>404: Page Not Found</h1>
      <p>This requested page does not exist.</p>
    </Container>
  </>
)

export default NotFoundPage
